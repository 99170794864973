<template>
  <div>
    <!-- Filters -->
    <tickets-list-filters @selectStatus="(val) => params.status = val.value"
      @selectCategory="(val) => params.category = val.value" :categoryOptions="[
        { label: $t('Todas'), value: 'all' },
        { label: $t('Financeiro'), value: 'financial' },
        { label: $t('Outro'), value: 'other' },
        { label: $t('Robô trader'), value: 'robot_trading' },
        { label: $t('Rede de afiliados'), value: 'affiliate_network' },
        { label: $t('Dados da conta'), value: 'account_data' }
      ]" :statusOptions="[
        { label: $t('Todos'), value: 'All' },
        { label: $t('Aberto'), value: 'Open' },
        { label: $t('Fechado'), value: 'Closed' }
      ]" />


    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <label>{{ $t("Mostrar") }}</label>
            <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="pageOptions"
              :clearable="false" class="per-page-selector d-inline-block mx-50" />
            <label>{{ $t("entradas") }}</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input v-model="searchQuery" class="d-inline-block mr-1"
                :placeholder="this.$i18n.t('Buscar...')" />
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table class="position-relative" striped responsive :items="items" :fields="fields" :busy="isLoading" show-empty
        :empty-text="$t('Nenhum registro correspondente encontrado')">
        <template #table-busy>
          <div class="text-center my-3">
            <b-spinner label="Loading..."></b-spinner>
            <strong>{{ $t('Carregando dados...') }}</strong>
          </div>
        </template>

        <template #cell(user)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar size="32" :src="renderImgAvatar(data.item.avatar)" :text="avatarText(data.item.fullname)" />
            </template>
            <b-link :to="{
              name: `${routerUserView}`,
              params: { id: data.item._id },
            }" class="font-weight-bold d-block limitString" v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-tooltip.hover.v-primary :title="data.item.fullname" variant="outline-primary">
              {{ data.item.fullname }}
            </b-link>
            <small class="text-muted">@{{ data.item.username }}</small>
          </b-media>
        </template>

        <template #cell(_id)="data">
          <b-link :to="{
            name: `${routerTicketView}`,
            params: { idTicket: data.item._id },
          }">
            {{ data.item._id }}
          </b-link>
        </template>

        <!-- Column: Email -->
        <template #cell(email)="data">
          <div class="text-nowrap">
            <span class="align-text-top">{{ data.item.email }}</span>
          </div>
        </template>

        <template #cell(category)="data">
          <div class="text-nowrap">
            <span class="align-text-top">{{
              $t(`${data.item.category}`)
            }}</span>
          </div>
        </template>

        <!-- Column: Balance -->
        <template #cell(valueUSD)="data">
          <div class="text-nowrap">
            <span class="align-text-top">{{
              moneyFormat(data.item.valueUSD)
            }}</span>
          </div>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge pill :variant="`light-${resolveUserStatusVariant(data.item.status)}`">
            {{ $t(`${data.item.status}`) }}
          </b-badge>
        </template>

        <!-- Column: Create Date -->
        <template #cell(createdAt)="data">
          <div class="text-nowrap">
            <span class="align-text-top">{{
              format(new Date(data.item.createdAt), "dd/MM/yyyy HH:mm")
            }}</span>
          </div>
        </template>

        <template #cell(updatedAt)="data">
          <div class="text-nowrap" v-if="data.item.status == 'Closed'">
            <span class="align-text-top">{{
              format(new Date(data.item.updatedAt), "dd/MM/yyyy HH:mm")
            }}</span>
          </div>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
            <template #button-content>
              <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
            </template>
            <b-dropdown-item v-b-modal.modal-send-email @click="() => (usernameToDisable = data.item)">
              <feather-icon icon="SendIcon" />
              <span class="align-middle ml-50">{{
                $t("Reenviar e-mail")
              }}</span>
            </b-dropdown-item>

            <b-dropdown-item v-b-modal.modal-disable-user @click="() => (usernameToDisable = data.item)">
              <feather-icon :icon="data.item.status == 'active' ? 'UserXIcon' : 'UserCheckIcon'
                " />
              <span class="align-middle ml-50">{{
                data.item.status == "active"
                  ? $t("Desativar usuário")
                  : $t("Ativar usuário")
              }}</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col cols="12" sm="6" class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            ">
            <span class="text-muted">{{ $t("Mostrando") }} {{ entriesFrom }} {{ $t("a") }}
              {{ entriesTo }} {{ $t("de") }} {{ totalRows }}
              {{ $t("entradas") }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col cols="12" sm="6" class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            ">
            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" first-number last-number
              class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BModal,
  BFormTextarea,
  VBTooltip,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { ref } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import { mapGetters, mapActions } from "vuex";
import { format } from "date-fns";
import { debounce } from "debounce";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import { required, min, max } from "@/libs/validations";
import Ripple from "vue-ripple-directive";
import TicketsListFilters from "./TicketsListFilters.vue";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BModal,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,

    localize,

    vSelect,

    TicketsListFilters
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      totalRows: 0,
      currentPage: 1,
      entriesTo: 0,
      entriesFrom: 0,
      searchQuery: "",
      API_URL: process.env.VUE_APP_API_URL,
      usernameToDisable: "",
      reasonDisable: "",

      required,
      min,
      max,

      fields: [
        { key: "_id", label: null },
        { key: "subject", label: null },
        { key: "category", label: null },
        { key: "status", label: null },
        { key: "createdAt", label: null },
        { key: "updatedAt", label: null },
      ],
      items: [],
      isLoading: false,
      params: {
        page: this.currentPage,
        limit: this.perPage,
        search: "",
        category: "all",
        status: "all",
      },
      sortByFilter: null,
      sortByOptions: [],
      sortDirectionFilter: null,
      sortDirectionOptions: [],

      openModalDeposit: false,
    };
  },
  computed: {
    ...mapGetters(["returnLocale", "returnTicketsList"]),
    routerTicketView() {
      return "customer-service-ticket-response";
    },
  },
  mounted() {
    this.renderTicketsList();

    this.fields[0].label = this.$i18n.t("ID do chamado");
    this.fields[1].label = this.$i18n.t("Assunto");
    this.fields[2].label = this.$i18n.t("Categoria");
    this.fields[3].label = this.$i18n.t("Status");
    this.fields[4].label = this.$i18n.t("Data de abertura");
    this.fields[5].label = this.$i18n.t("Data de encerramento");

    this.sortByOptions = [
      { label: this.$i18n.t("Data de cadastro"), value: "createdAt" },
      { label: this.$i18n.t("E-mail"), value: "email" },
      { label: this.$i18n.t("Nome completo"), value: "fullname" },
      { label: this.$i18n.t("Saldo"), value: "balance" },
      { label: this.$i18n.t("Status"), value: "status" },
      { label: this.$i18n.t("Usuário"), value: "username" },
    ];

    this.sortDirectionOptions = [
      { label: this.$i18n.t("Crescente"), value: "asc" },
      { label: this.$i18n.t("Decrescente"), value: "desc" },
    ];
  },
  watch: {
    returnLocale: function (oldVal, newVal) {
      this.fields[0].label = this.$i18n.t("ID do chamado");
      this.fields[1].label = this.$i18n.t("Assunto");
      this.fields[2].label = this.$i18n.t("Categoria");
      this.fields[3].label = this.$i18n.t("Status");
      this.fields[4].label = this.$i18n.t("Data de abertura");
      this.fields[5].label = this.$i18n.t("Data de encerramento");

      this.sortByOptions = [
        { label: this.$i18n.t("Data de cadastro"), value: "createdAt" },
        { label: this.$i18n.t("E-mail"), value: "email" },
        { label: this.$i18n.t("Nome completo"), value: "fullname" },
        { label: this.$i18n.t("Saldo"), value: "balance" },
        { label: this.$i18n.t("Status"), value: "status" },
        { label: this.$i18n.t("Usuário"), value: "username" },
      ];

      this.sortDirectionOptions = [
        { label: this.$i18n.t("Crescente"), value: "asc" },
        { label: this.$i18n.t("Decrescente"), value: "desc" },
      ];
    },
    returnTicketsList: function (oldVal, newVal) {
      this.totalRows = oldVal.total;
      this.entriesTo =
        oldVal.perPage * oldVal.currentPage > oldVal.total
          ? oldVal.total
          : oldVal.perPage * oldVal.currentPage;
      this.entriesFrom =
        oldVal.perPage * oldVal.currentPage + 1 - oldVal.perPage;
      this.items = oldVal.ticketList;
    },
    perPage: function (newVal) {
      this.params.limit = newVal;
      this.renderTicketsList();
    },
    currentPage: function (newVal) {
      this.params.page = newVal;
      this.renderTicketsList();
    },
    searchQuery: debounce(function (newVal) {
      this.params.search = newVal;
      this.renderTicketsList();
    }, 500),
    "params.status": debounce(function (newVal) {
      this.renderTicketsList();
    }, 300),
    "params.category": debounce(function (newVal) {
      this.renderTicketsList();
    }, 300),
    sortDirectionFilter: function (newVal) {
      this.params.sortdirection = newVal;
      this.renderTicketsList();
    },
  },
  methods: {
    ...mapActions(["usersList", "sendEmailAPI", "disableUserAPI"]),
    renderImgAvatar(img) {
      if (img)
        return img ? process.env.VUE_APP_API_URL + "/avatar/" + img : null;
    },

    userMaxRole(array) {
      let filtered = array.map((res) => res.role_id);
      return array.find((res) => res.role_id == Math.min(...filtered)).name;
    },

    renderTicketsList() {
      this.isLoading = true;
      this.$store
        .dispatch("getTicketsAll", this.params)
        .finally(() => {
          this.isLoading = false;
        });
    },

    sendEmail() {
      this.sendEmailAPI(this.usernameToDisable.id).then((response) => {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            icon: "CoffeeIcon",
            variant: "success",
            title: this.$i18n.t("E-mail enviado!"),
            text: this.$i18n.t(
              "O e-mail de convite foi reenviado com sucesso."
            ),
          },
        });
      });
    },

    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();

      this.$refs.disableUser.validate().then((success) => {
        if (success) {
          this.handleSubmit();
        }
      });
    },

    handleSubmit() {
      const data = {
        id: this.usernameToDisable._id,
        status:
          this.usernameToDisable.status == "active" ? "inactive" : "active",
        description: this.reasonDisable,
      };
      this.disableUserAPI(data)
        .then((response) => {
          this.$bvModal.hide("modal-disable-user");
          this.renderTicketsList();
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              icon: "CoffeeIcon",
              variant:
                this.usernameToDisable.status == "active"
                  ? "danger"
                  : "success",
              title:
                this.usernameToDisable.status == "active"
                  ? this.$i18n.t("Usuário desativado!")
                  : this.$i18n.t("Usuário ativado!"),
            },
          });
          this.reasonDisable = "";
        })
        .catch((err) => {
          /** */
        });
    },
  },
  setup() {
    format;

    const isAddNewUserSidebarActive = ref(false);

    const resolveUserRoleVariant = (role) => {
      if (role === "customer") return "primary";
      if (role === "employee_store") return "warning";
      if (role === "seller") return "success";
      if (role === "employee_adm") return "info";
      if (role === "administrator") return "danger";
      return "primary";
    };

    const resolveUserRoleIcon = (role) => {
      if (role === "customer") return "UserIcon";
      if (role === "employee_store") return "SettingsIcon";
      if (role === "seller") return "DatabaseIcon";
      if (role === "employee_adm") return "Edit2Icon";
      if (role === "administrator") return "ServerIcon";
      return "UserIcon";
    };

    const resolveUserStatusVariant = (status) => {
      if (status === "pending") return "warning";
      if (status === "active") return "success";
      if (status === "inactive") return "secondary";
      return "primary";
    };

    return {
      // Sidebar
      isAddNewUserSidebarActive,

      // Utilis
      avatarText,
      format,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.limitString {
  white-space: nowrap;
  max-width: 10em;
  overflow: hidden;
  text-overflow: ellipsis;

  .teste {
    background-color: #0101c7;
  }
}
</style>