<template>
  <b-card no-body>
    <b-card-header class="pb-50">
      <h5>
        {{ $t("Filters") }}
      </h5>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col cols="12" md="6" class="mb-md-0 mb-2">
          <label>{{ $t("Categoria") }}</label>
          <v-select
            v-model="selectedCategory"
            :options="categoryOptions"
            class="w-100"
            @input="(val) => $emit('selectCategory', val)"
          />
        </b-col>

        <b-col cols="12" md="6" class="mb-md-0 mb-2">
          <label>{{ $t("Status") }}</label>
          <v-select
            v-model="selectedStatus"
            :options="statusOptions"
            class="w-100"
            @input="(val) => $emit('selectStatus', val)"
          />
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import { BCard, BCardHeader, BCardBody, BRow, BCol } from "bootstrap-vue";
import vSelect from "vue-select";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    vSelect,
  },
  props: {
    statusOptions: {
      type: Array,
      default: () => [],
    },
    categoryOptions: {
      type: Array,
      default: () => [],
    },
    selectStatus: {
      type: String,
      default: null,
    },
    selectCategory: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      selectedCategory: this.selectCategory,
      selectedStatus: this.selectStatus,
    };
  },
  watch: {
    selectCategory(newValue) {
      this.selectedCategory = newValue;
    },
    selectStatus(newValue) {
      this.selectedStatus = newValue;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
